import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataFetchingClient, useQuery } from 'data-fetching-client';
import { CREATE_BUYER_OVERRIDES_SUBTOTALS, CREATE_BUYER_OVERRIDES_PREVIEW_SUBTOTALS } from '../data/queries';
import addGenericFloatingError from '../utils/addGenericFloatingError';
import updateCheckoutSessionSubtotals from '../utils/updateCheckoutSessionSubtotals';
import BuyerOverridesActionTypes from '../state/BuyerOverrides/actionTypes';
import { getBuyerOverridesLineItems, getBuyerOverridesPartialPaymentAmount } from '../state/BuyerOverrides/selectors';
import { usePaymentFormContext } from '../context/PaymentFormContext';
import useCalculateTax, { getShouldCalculateTaxOnSubtotalChange } from './useCalculateTax';
import { fetchBuyerOverridesSubtotals } from '../data/BuyerOverridesApi';
import { getTaxCalculationId, setCalculateTaxLoading } from '../state/calculateTaxSlice';
function useUpdateBuyerOverridesLoading(results) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: BuyerOverridesActionTypes.SET_LOADING,
      data: {
        isLoading: results.loading
      }
    });
  }, [dispatch, results.loading]);
}
// for the checkout page, after we have a checkoutSessionId from an existing payment link
export function useFetchDynamicSubtotals({
  checkoutSession,
  skip = false
}) {
  const client = useDataFetchingClient();
  const buyerOverridesLineItems = useSelector(getBuyerOverridesLineItems);
  const partialPaymentAmountForSubtotals = useGetPartialPaymentAmountForSubtotals({
    checkoutSession
  });
  const result = useQuery(CREATE_BUYER_OVERRIDES_SUBTOTALS, {
    skip,
    variables: {
      buyerOverrides: {
        discountCode: checkoutSession.getIn(['subtotalsPublicResponse', 'discountCodeResponse', 'discountCode']),
        lineItems: buyerOverridesLineItems,
        paymentAmount: partialPaymentAmountForSubtotals
      },
      paymentSessionId: checkoutSession.get('id')
    },
    onCompleted: ({
      subtotals
    }) => {
      updateCheckoutSessionSubtotals(client, subtotals);
    },
    onError: addGenericFloatingError
  });
  useUpdateBuyerOverridesLoading(result);
  return result;
}
export function useFetchDynamicSubtotalsWithTax({
  checkoutSession,
  skip = false
}) {
  const [result, setResult] = useState({
    data: undefined,
    loading: false,
    error: undefined
  });
  const client = useDataFetchingClient();
  const dispatch = useDispatch();
  const currentTaxCalculationId = useSelector(getTaxCalculationId);
  const {
    callCalculateTax
  } = useCalculateTax();
  const {
    formState
  } = usePaymentFormContext();
  const buyerOverridesLineItems = useSelector(getBuyerOverridesLineItems);
  const isAutomatedSalesTaxEnabled = checkoutSession.get('isAutomatedSalesTaxEnabled');
  const billingPostalCode = formState.getIn(['billingAddress', 'postalCode']);
  const shippingPostalCode = formState.getIn(['shippingAddress', 'postalCode']);
  const billingCountry = formState.getIn(['billingAddress', 'country']);
  const shippingCountry = formState.getIn(['shippingAddress', 'country']);
  const checkoutSessionId = checkoutSession.get('id');
  const discountCode = checkoutSession.getIn(['subtotalsPublicResponse', 'discountCodeResponse', 'discountCode']);

  // there are two useEffects here:
  // 1. when the taxCalculationId changes, we fetch the subtotals
  // 2. when the buyerOverridesLineItems change, we calculate tax if needed and fetch the subtotals

  useEffect(() => {
    async function fetchTaxAndSubtotals() {
      // need to use undefined here because redux state uses null and API expects undefined
      let taxCalculationId = currentTaxCalculationId || undefined;
      try {
        const shouldCalculateTax = getShouldCalculateTaxOnSubtotalChange({
          isAutomatedSalesTaxEnabled,
          billingPostalCode,
          shippingPostalCode,
          billingCountry,
          shippingCountry
        });
        if (shouldCalculateTax) {
          dispatch(setCalculateTaxLoading(true));
          taxCalculationId = await callCalculateTax();
        }
        setResult({
          data: undefined,
          loading: true,
          error: undefined
        });
        const subtotalsResponse = await fetchBuyerOverridesSubtotals({
          buyerOverrides: {
            discountCode: discountCode,
            lineItems: buyerOverridesLineItems
          },
          paymentSessionId: checkoutSessionId,
          taxCalculationId
        });
        updateCheckoutSessionSubtotals(client, subtotalsResponse);
        setResult({
          data: {
            subtotals: subtotalsResponse
          },
          loading: false,
          error: undefined
        });
      } catch (error) {
        setResult({
          data: undefined,
          loading: false,
          error
        });
        addGenericFloatingError();
      } finally {
        dispatch(setCalculateTaxLoading(false));
      }
    }
    if (!skip) {
      void fetchTaxAndSubtotals();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyerOverridesLineItems]);
  useEffect(() => {
    async function fetchSubtotals() {
      try {
        setResult({
          data: undefined,
          loading: true,
          error: undefined
        });
        const subtotalsResponse = await fetchBuyerOverridesSubtotals({
          buyerOverrides: {
            discountCode: discountCode,
            lineItems: buyerOverridesLineItems
          },
          paymentSessionId: checkoutSessionId,
          taxCalculationId: currentTaxCalculationId || undefined
        });
        updateCheckoutSessionSubtotals(client, subtotalsResponse);
        setResult({
          data: {
            subtotals: subtotalsResponse
          },
          loading: false,
          error: undefined
        });
      } catch (error) {
        setResult({
          data: undefined,
          loading: false,
          error
        });
        addGenericFloatingError();
      } finally {
        dispatch(setCalculateTaxLoading(false));
      }
    }
    if (!skip) {
      void fetchSubtotals();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTaxCalculationId]);
  return result;
}
// for the preview iframe, before we have a checkoutSessionId because we're creating a payment link
export function useFetchDynamicSubtotalsPreview({
  checkoutSession,
  discount,
  adjustments,
  portalId,
  currencyCode,
  lineItems,
  skip = false
}) {
  const client = useDataFetchingClient();
  const buyerOverridesLineItems = useSelector(getBuyerOverridesLineItems);
  const partialPaymentAmountForSubtotals = useGetPartialPaymentAmountForSubtotals({
    checkoutSession
  });
  const result = useQuery(CREATE_BUYER_OVERRIDES_PREVIEW_SUBTOTALS, {
    skip,
    variables: {
      buyerOverrides: {
        discountCode: checkoutSession.getIn(['subtotalsPublicResponse', 'discountCodeResponse', 'discountCode']),
        lineItems: buyerOverridesLineItems,
        paymentAmount: partialPaymentAmountForSubtotals
      },
      currencyCode,
      lineItems,
      portalId,
      adjustments,
      discount
    },
    onCompleted: ({
      subtotals
    }) => updateCheckoutSessionSubtotals(client, subtotals),
    onError: addGenericFloatingError
  });
  useUpdateBuyerOverridesLoading(result);
  return result;
}
export function useGetPartialPaymentAmountForSubtotals({
  checkoutSession
}) {
  const partialPaymentsInfo = checkoutSession.get('partialPaymentsInfo') && checkoutSession.get('partialPaymentsInfo').toJS();
  const dispatch = useDispatch();
  const partialPaymentAmount = useSelector(getBuyerOverridesPartialPaymentAmount);
  const initialPartialPaymentAmount = partialPaymentsInfo && partialPaymentsInfo.isPartialPaymentsEnabled && partialPaymentsInfo.balanceDue || undefined;
  const partialPaymentAmountForSubtotals = partialPaymentAmount || initialPartialPaymentAmount;

  // initializes partialPaymentAmount to balanceDue on first page load
  // when `partialPaymentAmount` is undefined
  if (!partialPaymentAmount && initialPartialPaymentAmount) {
    dispatch({
      type: BuyerOverridesActionTypes.SET_PARTIAL_PAYMENT_AMOUNT,
      data: {
        partialPaymentAmount: initialPartialPaymentAmount
      }
    });
  }
  return partialPaymentAmountForSubtotals;
}